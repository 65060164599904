<template>
  <div>
    <!-- <p class="font-weight-bold">
      <ConsoleLink to="/global-variable" class="text-primary">
        {{ $t('sideBar.global_variable') }} >
      </ConsoleLink> {{ $t('utils.add') }} {{ $t('sideBar.global_variable') }}
    </p> -->

    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <ConsoleLink to="/global-variable" class="text-primary">
          {{ $t('sideBar.global_variable') }}
        </ConsoleLink>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        {{ $t('utils.add') }} {{ $t('sideBar.global_variable') }}
      </a-breadcrumb-item>
    </a-breadcrumb>

    <AddGlobalVariable :permission="permission" />
  </div>
</template>

<script>
import AddGlobalVariable from '@/components/Price/Global-Variable/Add'
import ConsoleLink from '@/components/ConsoleLink/ConsoleLink.vue'

export default {
  components: {
    AddGlobalVariable,
    ConsoleLink,
  },
  computed: {
    permission() {
      return this.$store.getters['user/can']('global-variable')
    },
  },
  // watch: {
  //   permission(newValue) {
  //     if(!newValue.length || !newValue.includes('WRITE')) {
  //       this.$router.push({path: '/error/403', query: {...this.$route.query}})
  //     }
  //   },
  // },
}
</script>
