<template>
  <a-card class="my-2 rounded text-left">
    <p class="pbz-font subtitle-md-medium">
      {{ $t('utils.add') }} {{ $t('sideBar.global_variable') }}
    </p>
    <a-form-model
      ref="formData"
      novalidate
      :model="formData"
      @submit.prevent="onSave"
    >
      <fieldset :disabled="loadingSave">
        <label class="mb-1">
          {{ $t('price_setting.label_variable') }} <sup class="text-danger">*</sup>
        </label>
        <a-form-model-item
          prop="symbol"
          class="mb-3"
          :rules="[
            {
              required: true,
              message: $t('price_setting.error_variable_name'),
            },
            {
              pattern: /^[0-9a-zA-Z_]*$/,
              message: $t('price_setting.error_variable_name_pattern'),
            },
            {
              max: 30,
              message: $t('price_setting.error_max_variable_name'),
            }
          ]"
        >
          <a-input
            v-model="formData.symbol"
            size="large"
            allow-clear
            :disabled="loadingSave"
          />
        </a-form-model-item>
        <label class="mb-1">
          {{ $t('price_setting.label_description') }}
        </label>
        <a-form-model-item
          prop="name"
          class="mb-3"
          :rules="[{
            max: 30,
            message: $t('price_setting.error_description'),
          }]"
        >
          <a-input
            v-model="formData.name"
            size="large"
            allow-clear
            :disabled="loadingSave"
          />
        </a-form-model-item>
        <label class="mb-1">
          Value <sup class="text-danger">*</sup>
        </label>
        <a-form-model-item
          prop="default_value"
          :rules="[
            {
              required: true,
              message: $t('price_setting.error_value_empty'),
            },
            {
              validator: (rule, value, cb) => {
                ('' + value).length > 12 ? cb(true) : cb()
              },
              message: 'Maximum 12 digit',
            }
          ]"
        >
          <a-input-number
            v-model="formData.default_value"
            size="large"
            class="w-100"
            :min="0"
            :disabled="loadingSave"
          />
        </a-form-model-item>
        <div class="text-right mt-3">
          <a-button
            type="link"
            size="large"
            class="text-primary font-weight-bold mr-2"
            :disabled="loadingSave"
            @click="$router.push({ path: '/global-variable', query: $route.query })"
          >
            {{ $t('utils.cancel') }}
          </a-button>
          <a-button
            v-if="permission.includes('WRITE')"
            type="primary"
            size="large"
            class="font-weight-bold"
            :loading="loadingSave"
            html-type="submit"
          >
            {{ $t('utils.save') }}
          </a-button>
        </div>
      </fieldset>
    </a-form-model>
  </a-card>
</template>

<script>
import { crudPriceVariable } from '@/api/price'

export default {
  props: ['permission'],
  data() {
    return {
      formData: {
        symbol: '',
        name: '',
        default_value: '',
      },
      loadingSave: false,
    }
  },
  methods: {
    onSave() {
      this.$refs.formData.validate(async valid => {
        if (valid) {
          const query = this.$route.query
          this.loadingSave = true
          // price/ADDGLOBALVARIABLE
          await crudPriceVariable({
            method: 'post',
            data: {
              business_id: query.business_id,
              category: 'GLOBAL',
              is_show: true,
              ...this.formData,
            },
          })
          .then(() => {
            this.$message.success('Global Variable has been saved.')
            this.$router.push({ path: '/global-variable', query })
          })
          .catch((err) => {
            const { message } = err?.response?.data || {}
            this.$message.error(message || 'Failed to save Global Variable')
          })
          .finally(() => { this.loadingSave = false })
        }
      })
    },
  },
}
</script>
